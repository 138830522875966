const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.wecomplai.forwoodsafety.com',
    APP_CLIENT_ID: '46hta3v48911as5pe5tvi05dkh',
    USER_TOKEN_URL: 'https://nrsmj7ztbk.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://vyusi3glza.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.wecomplai.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.wecomplai.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.wecomplai.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.wecomplai.forwoodsafety.com',
    WEBSOCKET: 'wss://hmys0usw60.execute-api.us-west-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;